@import "../theme/base/responsive.scss";
@import '../theme/base/colors.scss';
@import 'abstracts/_functions.scss';

.oh {
    opacity: .5;
}

footer {
    background-color: $main;
    padding-top: 60px;
    color: $text-second;

    a {
        color: $text-second;

        &:hover {
            color: $text-second;
        }

    }

    .logo {
        font-size: rem(14);

        img {
            width: 100px;

            &:hover {
                box-shadow: 0px 4px 20px 0px rgba(192, 192, 192, 0.15);
                border-radius: 50%;
            }
        }

        .top {
            .footer-items {
                ul {
                    li {
                        margin-bottom: 20px;

                        &:first-of-type {
                            a {

                                color: $second;
                                font-weight: bold;
                            }
                        }
                    }
                }
            }
        }


        i {
            color: $second;
        }
    }

    .search {
        height: 100%;
        display: flex;
        align-items: center;

        &-input {
            display: flex;

            border-radius: 8px;
            background: rgba(255, 255, 255, 0.08);
            width: 100%;

            input {
                background-color: transparent;
                width: 100%;
                border: none;

                &:hover,
                &:focus {
                    color: $white !important;
                    background-color: transparent;
                    border: none;
                    outline: none;
                    box-shadow: none;
                }
            }
        }

        &-icon {
            width: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: .5;
        }

    }

    ul {
        li {
            margin-bottom: rem(15);
        }
    }

    @include respond("tab-port") {

        footer {
            padding: 20px 0;

            .top {
                .footer-items {
                    ul {
                        text-align: center;

                        li {
                            // &:not(:first-child){
                            //     display: none;

                            // }
                        }
                    }

                }
            }

            .middle {
                .contact {
                    text-align: center;
                }

                .email {
                    width: 100%;
                    text-align: center;
                }

                .socials {
                    ul {
                        justify-content: center;
                    }
                }
            }

            .bottom {
                text-align: center;
            }
        }
    }
    .bottom {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        background: #1B2D4C;
        padding: rem(15) 0;
    
    
        span {
            transform: translateY(4px);
            margin-right: 5px;
            margin-left: 5px;
            display: inline-block;
        }
        .info{
            display: flex;
            justify-content: space-between;
        }
    
        
        @include respond("tab-port") {
            text-align: center;
            .info{
                justify-content: center;
                flex-wrap: wrap;
                width: 100%;
                max-width: unset;
            }
        }
    }
}



@include respond("tab-port") {

    footer {
        .footer-items {
            display: none;
        }
    }

    .logo{
        margin-bottom: 20px;

        p {
            font-size: 22px;
            text-align: left;
        }
    } 
}