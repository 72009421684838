@import "../theme/base/colors.scss";
@import "abstracts/functions";
@import "../theme/base/responsive.scss";

.links {
  padding: rem(50) 0;

  @include respond("tab-port") {
    padding: rem(20) 0;
  }

  &-slider {
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  .link {
    display: block;
    height: 170px;
    color: $black;
    font-size: 12px;
    border-left: 1px solid #ccc;
    line-height: normal;
    overflow: hidden;

    &-photo {
      margin-bottom: 10px;

      img {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        object-fit: cover;
      }
    }
    &-url, &-detail{
      height: 40px;
    }
    &-url{
      display: none;
      svg{
        transform: scale(.6);
      }
    }
    &:hover{
      .link-detail{
        display: none;

      }
      .link-url{
        display: block;
      }
    }
  }
}

.links-page {
  .link {
    color: $main;
    border: 1px solid #ccc;
    border-radius: 8px;
    display: block;
    cursor: pointer;

    &-detail {
      display: flex;
      align-items: center;
      height: 100%;
      flex-wrap: wrap;
      padding: 0 10px;
    }

    &-photo {
      width: 100px;

      img {
        width: 80px;
        height: 80px;
        object-fit: cover;
        border-radius: 5px;
      }
    }


    &-name {
      overflow: hidden;
      font-size: rem(14);
    }

    &-more {
      font-weight: 500;

      span {
        font-size: rem(14);
      }

      svg {
        margin-left: 5px;
        transform: scale(.7) translateY(-2px);
      }

      margin-top: rem(15);

    }
  }

}