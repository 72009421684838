
@import '../theme/base/colors.scss';
.loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: $main;
    height: 100%;
    display: flex;
    cursor: pointer;
    z-index: 123;
    justify-content: center;
    align-items: center;
    height: 100vh;
    svg *{
      stroke: $white;
    }
  }
  
  .spinner {
    width: 160px;
    height: 160px;
    animation: spin 1s linear forwards;
  }
  
  @keyframes spin {
    0% { transform: scale(1); }
    100% { transform: scale(2); }
  }
  