@import "../theme/base/colors.scss";
@import "../theme/base/responsive.scss";

section.boards {
    min-height: 400px;
    margin-bottom: 40px;

    .board {
        color: $main;
        border: 1px solid #ccc;
        border-radius: 8px;
        display: block;

        &-detail {
            display: flex;
            align-items: center;
            height: 100%;
            flex-wrap: wrap;
        }

        &-photo {
            padding: 15px 0;

            img {

                height: 200px;
                object-fit: cover;
                border-radius: 5px;
            }
        }

        &-position {
            overflow: hidden;
            margin-bottom: 20px;
        }

        &-more {
            text-align: right;
            font-weight: 500;

        }
    }

    h3 {
        color: $main;
        margin-bottom: 20px;
    }


    @include respond("tab-port") {
        .buttons{
            li{
                height: 40px;
                font-size: 12px;
            }
        }
        .board {
            &-detail {
                padding: 15px 0;
            }

            &-name,
            &-more span {
                font-size: 12px;
            }

            &-position {

                margin-bottom: 0px;
            }

            &-photo {
                img {
                    height: 80px;
                }
            }
        }
    }
}



.cursor-pointer {
    cursor: pointer;
}

section.board-detail {
    .board {
        padding: 15px;

        &-photo {
            height: 300px;
            text-align: center;

            img {
                border-radius: 6px;
                height: 100%;
                width:100%;
                object-fit: contain;
            }
        }

        &-info,
        &-description {
            border-radius: 12px;
            border: 1px solid #CDD5DF;
            background: #F8FAFC;
            margin: 20px 0;
            padding: 15px;
        }

        &-info {
            display: flex;
            flex-wrap: wrap;
            align-items: center;

            >div {
                height: 100%;
                width: 100%;
            }
        }
    }
}