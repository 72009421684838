@import "../theme/base/colors.scss";
@import "../scss/main.scss";
@import "abstracts/functions";

.commission {
  color: $main;

  &.detail {
    .commission-list {
      .commission {
        height: 100px;
      }
    }
  }

  .members {
    .member {
      padding: 1px 10px;
      margin-bottom: 20px;
      background-color: lighten($second, 40);
      color: $main;

      >div {
        margin: 13px 0;
      }
    }
  }


  .commission-list {

    .commission {
      color: $main;
      position: relative;
      cursor: pointer;
      border-radius: 8px;
      border: 1px solid #CDD5DF;
      padding: rem(15);
      height: rem(150);
      margin-bottom: rem(20);
      color: var(--Greyscale-800, #202939);
      font-size: rem(16);

      &-name {
        font-weight: 600;

      }

      line-height: 24px;
      transition: all .2s linear;

      &-more {
        transition: all .2s linear;
      }

      &:hover {
        font-weight: bold;
        background-color: $second;
        color: $white;

        svg * {
          stroke: white;
        }

        .commission-more {
          margin-left: 20px;
          display: inline-block;
        }
      }




    }

    .commission-photo {
      border-radius: 10px;
      overflow: hidden;
    }

    .commission-desc {
      font-size: 17px;
    }
  }
}