@font-face {
  font-family: 'TTInterfaces';
  src: url('./assets/fonts/TTInterfaces/TTInterfaces-Regular.otf');
}

* {
  margin: 0;
  padding: 0;
}

p {
  margin-bottom: 0;
}

body {
  font-family: 'TTInterfaces';
}

li {
  list-style-type: none;
}

a {
  color: initial;
}

a:hover {
  text-decoration: none;
  color: unset;
}

ul {
  padding: 0;
  margin: 0;
}
.cp{
  cursor: pointer;
}

img {
  width: 100%;
}

.slide-in,
.slide-out {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: black;
  transform-origin: bottom;
}

.slide-out {
  transform-origin: top;
}