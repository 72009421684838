@import "../theme/base/colors.scss";
@import "../scss/main.scss";
@import "../theme/base/responsive.scss";
#login {
  .bg-login {
    min-height: 100vh;
    background-color: $main;
    padding: 50px;
    img {
      width: 30%;
    }
  }
  .gohome {
    padding: 50px;
  }

  .login {
    width: 60%;
    color: $main;
    margin: auto;
  }

  @include respond("phone-land") {
    .bg-login {
      display: none!important;
    }
  }
}
