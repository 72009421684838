@import "../theme/base/colors.scss";
@import "abstracts/functions";
@import "../theme/base/responsive.scss";

section.newsList {
  padding: rem(50) 0;

  @include respond("tab-port") {
    padding: rem(20) 0;
  }
}

.buttons {
  li {

    &.active,
    &:hover {
      border: 1px solid $second;
      background: var(--Blue-50, #EFF8FF);
      color: $second !important;
    }
  }
}

.news {
  border: 1px solid #ccc;
  background: $white;
  line-height: normal;
  border-radius: 5px;
  color: $main;
  aspect-ratio: 4/3;
  //   position: relative;
  transition: 0.3s linear;
  cursor: pointer;

  &:hover {
    img {
      transform: scale(1.2);
    }
  }



  &-photo {
    height: 200px;
    object-fit: cover;
    margin: 15px;
    overflow: hidden;

    img {
      border-radius: 5px;
      transition: 0.2s linear;
      object-fit: cover;
      height: 100%;
    }
  }

  &-detail {
    padding: 15px;
    padding-bottom: 20px;
    position: relative;
    color: $main;

  }

  &-title {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    height: 40px;
    overflow: hidden;
    margin-bottom: rem(10) !important;
  }

  &-content {
    height: 145px;
    font-weight: 400;
    font-size: 14px;
    overflow-y: hidden;
  }

  &-date {
    position: absolute;
    left: 15px;
    bottom: 15px;
    font-size: 12px;

    i {
      color: $second;
    }
  }
}

.news-list {
  .news {
    cursor: pointer;

    &:hover {
      cursor: pointer;
      border: 1px solid $second;
      background: var(--Blue-50, #EFF8FF);
    }
  }
}

.news-detail-page {
  img {
    transform: unset !important;
    cursor: pointer;
  }

  .news-main-photo {
    position: relative;
    height: 500px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  
  }

  .news {
    iframe{
      height: 350px;
    }
    aspect-ratio: unset;
    padding: rem(20);
    cursor: unset;

    &:hover {
      box-shadow: none !important;
    }

    &-date {
      font-size: rem(12);
      position: static;
      margin-top: rem(5);

    }

    &-header {
      padding: rem(10);
      font-size: rem(25);
      border-left: 2px solid $second;



    }

    &-content {}

    &-slider {
      img {
        aspect-ratio: 4/3;
        object-fit: cover;
        border-radius: 8px;
      }

      margin: rem(40) 0;
      position: relative;
      color: $text-main;
      overflow: hidden;

      .swiper {

        padding: 0px;

        .news-photo {
          height: 200px;

          img {
            height: 100%;
            object-fit: cover;
          }
        }

        .slide-detail {
          position: absolute;
          width: 100%;
          padding: 30px;
          text-align: center;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          background-color: rgba(239, 239, 239, 0.5);
        }

        &-button-prev,
        &-button-next {
          width: 48px;
          height: 48px;
          background-color: $white;
          left: rem(20);
          color: $main;
          font-size: 24px;
          border-radius: 50%;
          opacity: .5;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }

        &-button-prev::after,
        &-button-next::after {
          content: "";
          width: 100%;
          border-radius: 100%;
          height: 100%;
          background-image: url("../images/icons/left.svg") !important;
          background-repeat: no-repeat;
          background-position: center center;
        }

        &-button-next::after {
          transform: rotate(180deg);
          background-image: url("../images/icons/left.svg") !important;
        }

        &-button-next {
          left: unset;
          right: rem(20);
        }
      }

    }

    &-share {
      background-color: #F9FAFB;
      border-radius: 8px;
      padding: rem(15);
      display: flex;
      align-items: center;

      label {
        margin-bottom: 0;
      }

      i {
        color: #6B7280;
      }

      .socials {
        &-list {
          display: flex;

          &-item {
            padding: 0 rem(20);
            cursor: pointer;

            &:not(:last-of-type) {
              border-right: 1px solid #6B7280;
            }
          }
        }
      }
    }


  }

  .news-list {
    .news-desc {
      height: 40px;
      overflow: hidden;
    }
  }

  @include respond("tab-port") {
    padding: rem(20) 0;

    .news {
      padding: rem(10);

      &-detail {
        padding: rem(5);
      }

      &-header {
        font-size: rem(18);
      }

      &-slider {
        margin: rem(20) 0;
      }
    }

    .news-lists {
      overflow: hidden;
      overflow-x: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;
    }

    .news-list {
      display: flex;
      width: max-content;
      

      >a {
        max-width: calc(100vw - 120px);
        margin-right: 10px;
      }
    }
  }
}