.socials {
    display: flex;
    &.home {
        display: block;
        position: absolute;
        transform: translate(-80px , 80px);
        a {
            width: 40px;
            height: 40px;
            border-radius: 32px;
            margin-bottom: 20px;
            background: var(--Greyscale-400, #9AA4B2);
            border: 1px solid #E3E8EF;

        }
    }
    &.header{
        justify-content: space-between;
    }

    &-item {
        a {
            width: 30px;
            height: 30px;
            position: relative;
            display: inline-block;
            border-radius: 4px;
            background: #ffffff33;

            svg {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
}