@import "../theme/base/colors.scss";
@import "abstracts/functions";

.materials {
    .material {
        display: flex;
        border-radius: 12px;
        background-color: #F8FAFC;
        border: 1px solid #E3E8EF;
        padding: 20px;
        transition: all .3s linear;

        &-name {
            font-weight: bold;
            display: flex;
            width: 100%;
            justify-content: space-between;
        }

        &:hover {
            border-color: $second;

            *,
            span {
                transition: all .3s linear;
                color: $second;
            }

            .material-name {

                svg * {
                    stroke: $second
                }
            }
        }
    }
}