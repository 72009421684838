@import "../theme/base/colors.scss";

.notifications {
  .notification {
    position: relative;
    padding-right: 40px;
    border: 1px solid $second;
    padding: 15px;
    height: 80px;
    cursor: pointer;
    transition: .2s linear;
    &:hover {
      box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
    }
    a {
      position: absolute;
      transform: translateY(-50%);
      top: 50%;
      right: 10px;
    }
  }
}
