@import "../theme/base/colors.scss";
@import "abstracts/functions";
@import "../theme/base/responsive.scss";
section.activities {
  min-height: 400px;
  margin-bottom: 40px;

  .activity {
    background-color: lighten($second, 35);
    color: $white;
    display: block;
    position: relative;

    .activity-detail {
      padding: 20px;
      color: $main;
    }

    .activity-title {
      overflow: hidden;
      margin-bottom: 10px;
    }

    .activity-date {
      display: flex;
      justify-content: space-between;
    }

    .icon {
      position: absolute;
      right: 20px;
      font-size: 20px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

}

.page-title {
  font-size: rem(14);
  color: $black;
}

section.activityList {
  background-color: #F8FAFC;
  padding: rem(50) 0;

  .activity {
    padding: 20px;
    background-color: $white;
    margin-bottom: 20px;
    border-radius: 8px;
    width: calc(100%/3 - 10px);

    transition: all .2s linear;
    &-info {
      background: rgba(245, 245, 245, 0.3);
      padding: 20px;
      margin: 20px;
      position: relative;

    }

    &-photo {
      padding: 15px;
      background-color: #B3C9EB;
    }

    &.icon {
      border-radius: 8px;
      border: 1px solid $text-main;
      background-color: $white;

    }
    &:hover{
      transform: translateY(-10px);
      border: .1px solid $second;
    }
  }

  .activitiesList{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

.activity-photo img {
  width: 100%;
  height: 400px;
  object-fit: cover;
}

@include respond("tab-port") {

  section.activityList {
    padding: rem(20) 0;
    .activitiesList{
      flex-wrap: nowrap;
      overflow-x: auto;
      -ms-overflow-style: none; 
      scrollbar-width: none;

      .activity{
        width: 300px;
        margin-right: 20px;
        &-title{
          width: 2500px;
        }
      }
    }


  }

}