@import "../theme/base/colors.scss";
@import "../theme/base/responsive.scss";
@import "abstracts/functions";

header {
  background-color: $white;
  position: relative;
  width: 100vw;

  .logo {
    font-size: rem(20);

    img {
      width: rem(104);
    }

    img:hover {
      box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
      border-radius: 50%;
    }

    p {
      color: $main;
      margin-left: 20px;
      font-weight: bold;
    }
  }

  .top {
    align-items: center;

    >div {
      margin-left: 25px;
    }

    .language {
      padding: 5px 10px;
      position: relative;
      background: $second;
      color: $white;
      border-radius: 4px;
      text-align: center;
      cursor: pointer;

      &:hover {
        .language-menu {
          display: block;
        }
      }

      .language-menu {
        display: none;
        background-color: $main;
        box-shadow: 0 0 3px #00000032;
        padding: 5px;
        position: absolute;
        z-index: 2;
        top: 100%;
        right: 0%;

        // transform: translateX(-50%);
        li {
          color: $white;
          transition: 0.2s linear;

          &:hover {
            background-color: $second;
            cursor: pointer;
            // color:$main
          }
        }
      }
    }

    .search {
      border-radius: 8px;
      background: lighten($main, 70);
      position: relative;

      input {
        background: transparent;
        border: none;
        outline: none;
      }

      &-icon {
        top: 5px;
        position: absolute;
        right: 10px;
      }
    }

    .login {
      i {
        display: none;
      }
    }
  }

  .inner {
    padding: rem(12) 0;

  }

  .notification {
    position: relative;

    &::after {
      content: '';
      width: 10px;
      height: 10px;
      border-radius: 50%;
      position: absolute;
      top: -2px;
      right: -2px;
      background-color: $red;
    }
  }

  &.fixed {
    position: fixed;
    transition: 0.2s linear;
    z-index: 12;

    // padding: 0.5rem;
    .inner {
      display: none;
    }
  }

  nav {
    width: calc(100% - 180px);
    height: 60px;
    display: flex;
    align-items: center;
    background-color: $main;

    ul {
      li {
        .nav-link.active {
          font-weight: bold;
        }

        cursor: pointer;

        &.active {
          >a {
            font-weight: bold;
            transition: 0.1s linear;
            position: relative;
          }

        }

        a.active {
          font-weight: bold;
          transition: 0.1s linear;
          position: relative;
        }

        &.dropdown {
          position: static;
        }
      }

      .link-text {
        color: $text-second;
      }
    }

    .mobile {
      display: none;
    }
  }

  .nav {
    justify-content: space-between;
    &.mobile{
      display: none;
    }
  }

  .dropdown {
    button {
      outline: none;

      &:focus {
        outline: none;
        border: none;
        box-shadow: none;
      }

      &::after {
        display: none;
      }

      color:$white !important;
    }

    .dropdown-menu {
      border: none;
      min-height: 70px;
      padding: 8px;
      bottom: 0;
      transition: 0.2s linear;

      a {

        &.dropdown-item {
          padding: 0;
          padding: 5px 10px;
          transition: 0.2s linear;
          border-radius: 3px;
          margin-top: 10px;

          &:hover {
            background-color: $second;
            color: $white
          }
        }
      }

    }
  }

  .mobile-menu-btn {
    border-radius: 8px;
    width: 50px;
    height: 50px;
    position: relative;
    padding: 10px;
    border: 1px solid var(--Greyscale-300, #CDD5DF);
    background: var(--Primary-white, #FFF);
    display: none;
    cursor: pointer;

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  @include respond("tab-land") {
    nav {
      height: 0;
    }

    .mobile-menu-btn {
      display: block;
    }


    .logo {
      margin-bottom: 0;

      img {
        width: 60px;
      }
    }

    .nav {
      display: none;

      &.mobile {
        .mobile-sub-menu{
          position: absolute;
          background-color: $white;
          width: 100%;
          top: 80px;
          height: 100vh;
          right: -100%;
          transition:all .3s linear;
          z-index: 123123;
          &.active {
            right: 0;
          }
        }
        position: absolute;
        border-top: 1px solid #eee;
        z-index: 123;
        width: 100vw;
        top: 84px;
        display: flex;
        left: -100%;
        padding: 10px;
        background-color: $white;
        height: calc(100vh - 80px);
        justify-content: space-between;
        flex-direction: column;
        transition:all .3s linear;
        &.active {
          left: 0;
        }


        >div {
          height: max-content;
        }

        .nav-item {
          width: 100%;

          &.active {
            height: max-content;

            .dropdown-menu {
              position: static;
              display: block !important;
            }
          }

          .nav-link {
            display: block;
            color: $main;
            padding: 0.3rem;
          }
        }

        .bottom {
          svg * {
            fill: #697586
          }
        }

        .top {
          .language {
            border-radius: 8px;
            height: 48px;
            width: 48px;
            line-height: 40px;
            display: flex;
            border: 1px solid var(--Neutral-300, #CDD5DF);
            background: $white;
            color: $black;

            &.active {
              color: $second;
              border: 1px solid var(--Primary-500, #2970FF);

            }

          }
        }

       
      }
    }

    &.fixed {
      box-shadow: 0 3px 3px #00000032;

      .inner {
        margin-top: 10px;
        display: block;
      }
    }
  }

  @include respond("tab-port") {

    .inner {

      .search,
      .language {
        display: none;
      }
    }

    .nav {
      .nav-item {
        .nav-link {
          display: block;
          width: 100%;
          padding: 0.5rem;
        }
      }

      &.mobile {
        .top {
          .search {
            &-icon {
              left: 5px;
              right: 0;
              top: 12px;
              width: max-content;

            }

            input {
              height: 48px;
              padding-left: 30px;
            }
          }

        }
      }
    }



    .top {
      .login {
        i {
          display: block;
        }

        span {
          display: none;
        }
      }

      >div {
        &.language {
          padding-right: 10px;
        }

        margin-left: 10px;
      }
    }

    .inner {
      .logo {
        p {
          width: 150px;
          font-size: 15px;
        }
      }
    }
  }

  @include respond("phone") {
    .logo {
      p {
        margin-left: 10px;
      }
    }
  }
}

.divider {
  height: 172px;
}