@import "../../theme/base/colors.scss";
@import "../abstracts/functions";

@import "../../theme/base/responsive.scss";

.frame {
    background-color: #EEF2F6;
    width: 100%;
    height: 150px;
    border-radius: 12px;
    margin: 20px auto;
    padding: rem(50);
    position: relative;
    overflow: hidden;

    &-name {
        font-weight: bold;
        color: #202939;
        font-size: rem(30);
    }

    &-photo {
        position: absolute;
        top: 0;
        right: 0;
        width: 35%;
        height: 100%;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;

            &.isCover {
                object-fit: cover;
            }
        }
    }

    a,
    i {
        font-size: 12px;
        margin-right: 10px;
        color: #697586
    }

    @include respond("phone") {
        padding: rem(20);
        display: flex;
        height: 100px;
        align-items: center;
        img {
            display: none;
        }
        &-name{
            font-size: rem(20);
          
        }
    }
}