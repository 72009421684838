@import "../theme/base/colors.scss";
@import "../theme/base/responsive.scss";
@import "abstracts/functions";

label,
.label {
  color: $main;
  font-weight: bold;
  font-size: 20px;

}

.slider {
  position: relative;
  color: $text-main;
  // overflow: hidden;

  .slide-content * {
    color: $white !important;
    height: 50px;
    overflow: hidden;
  }

  .tabs {
    display: flex;
    justify-content: space-between;

    .tab {
      width: calc(50% - 5px);

    }
  }

  &-news-list {
    .news {
      aspect-ratio: 3/1;
      margin-bottom: rem(10);
      padding: rem(15);

      &-title {
        margin-bottom: rem(5);
        color: $black;

      }

      &-desc {
        height: 35px;
        overflow: hidden;
        font-size: 14px;

        * {
          text-align: left;
        }
      }
    }
  }

  .swiper {
    padding: 0px;
    overflow-y: visible;

    &-pagination {
      z-index: 3;
      text-align: left;
      left: 10px !important;
      bottom: 15px !important;
      transform: unset !important;
    }
  }

  .slide {
    height: 500px;
    padding-top: 0;
    overflow: hidden;
    position: relative;
    border-radius: 8px;

    &::after {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      // background: #00000032;
      background: linear-gradient(180deg, rgba(17, 17, 39, 0.00) 0%, rgba(17, 24, 39, 0.95) 100%);
    }

    img {
      height: 100%;
      object-fit: cover;
      border-radius: 8px;
    }
  }

  .slide-detail {
    position: absolute;
    width: 100%;
    padding: 0 30px;
    bottom: 50px;
    left: 0;
    z-index: 2;
    color: $white;

    .slide-title {
      h5 {
        font-weight: bold;
        font-size: rem(20);
      }
    }
  }


}

.about {
  display: flex;
  justify-content: space-between;

  &-item {
    border-radius: 8px;
    background: #F8F3E7;
    padding: rem(20);
    transition: all .3s ease-in;
    cursor: pointer;
    width: calc(100%/3 - 10px);

    &:nth-of-type(2n) {
      background: #F8F8FC;
    }

    &:nth-of-type(3n) {
      background: #E9E9F6;
    }
    .onhover{
      display: none;
    }
    &:hover {

      .main:not(.first) {
        animation: rotate .3s linear forwards;
      }
      
      .main.first svg{
        display: none;
      }
      .onhover{
        display: block;
      }

    }
  }


  &-title {
    font-weight: bold;
  }

  &-desc {
    margin-top: 20px;
  }

  &-detail {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;

    .about-more {
      width: 100px;
    }

  }

  &-more {
    margin-bottom: 20px;
  }

  &-icon {
    position: relative;
    height: rem(200);

   
    .main {
      width: 200px;
      height: 200px;
      transition: all 0s linear;
    }

    svg {
      width: 100%;

      position: absolute;
      right: 20px;
      bottom: 20px;
    }
  }

}

hr {
  margin: 42px 0;
}


@keyframes rotate {
  0% {
    transform: translateY();
  }
  
  100% {
    transform: translateY(-10px);
  }
}

@keyframes rotate2 {
  0% {
    opacity: 0;
  }
  
  100% {
    opacity: 1;
  }
}


@include respond("tab-port") {
  .slider {
    .slide {
      height: 200px;

      &-detail {
        font-size: 13px;
        padding: 10px;
        bottom: 0;
      }

      &-title {
        h5 {
          font-size: 12px !important;
        }
      }

      &-content {
        display: none;
      }
    }

  }

  .swiper {
    .swiper-pagination {
      bottom: 0px !important;
    }
  }

  .about {
    overflow: hidden;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &-item {
      width: 300px;
      margin-right: 20px;
    }
  }

  label {
    font-size: 12px;
  }
}