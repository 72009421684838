@import "abstracts/functions";
@import "../theme/base/colors.scss";
@import "../theme/base/responsive.scss";

section.galleryList {
    padding: rem(50) 0;
    background-color: #F8FAFC;

    @include respond("tab-port") {
        padding: rem(20) 0;
    }

    .gallery {
        &-item {
            overflow: hidden;

            img {
                border-radius: 12px;
                aspect-ratio: 3 / 2;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}



.media-page {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;

    .medias {
        width: calc(100%/3 - 10px);
        margin-bottom: 10px;
        box-shadow: 0 1px 2px #00000032;

        @include respond("tab-port") {
            width: 100%
        }

        border-radius: 12px;
        overflow: hidden;

        .media-box {
            position: relative;
            margin-bottom: 30px;
            aspect-ratio: 3/2;

            cursor: pointer;
            height: 100%;

            .media-photo {
                width: 100%;
                height: 100%;
                border-radius: 12px;
                overflow: hidden;

               
                img {
                    width: 100%;
                    height: 100%;
                    aspect-ratio: 3/2;
                    object-fit: cover;
                }
            }

            &:hover {
                .media-title {
                    bottom: 0;
                }
            }

            .media-title {
                position: absolute;
                bottom: -100%;
                width: 100%;
                overflow: hidden;
                font-weight: bold;
                font-size: 14px;
                background: $main;
                transition: all .3s ease-in-out;
                color: $white;
                padding: 15px;

            }
        }
    }
}

.swiper {
    padding-top: 0;

    .slide {
        width: 100%;

        img {
            height: 500px;

            @include respond("tab-port") {
                height: unset;
            }

            width: 100%;
            object-fit: cover;
        }
    }

    .swiper-pagination-bullets {
        bottom: -20px;
    }


}