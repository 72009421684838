@import "../theme/base/colors.scss";

.search-page {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: $main;
  z-index: 11231;
  top: 0;
  color: $black;
  left: 0;

  .search-input {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    text-align: center;
    color: $white;
    transform: translate(-50%, -50%);

    input {
      border: 1px solid $second;
      background: transparent;
      min-width: 300px;
      padding: 5px 10px;
      height: 50px;
      width: 50%;
      color: $second;

      &:focus {
        outline: none !important;
      }
    }
  }
}

.search-items {
  border-radius: 12px;
  box-shadow: 0px 0px 64px 0px rgba(0, 0, 0, 0.08);
  padding: 20px 30px;
  margin-top: 20px;

  .search-title {
    font-weight: bold !important;
  }

  .search-item {

    &-title {
      font-weight: bold;
      margin-top: 10px;
    }

    &-desc {
      height: 50px;
      overflow-y: hidden;

      a {
        color: $second
      }
    }
  }
}