@import "../theme/base/colors.scss";
@import "../theme/base/responsive.scss";
.btn-send {
    background: rgba(76, 132, 219, 0.10);
}

.text {
    font-size: 12px;
}

.icon {
    background-color: var(--Blue-50, #D1E9FF);
    border-radius: 50%;
    width: 50px;
    height: 50px;
    position: relative;

    svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.contact {
    .info {
        max-width: 250px;

    }

}

.inner {
    &.brr {
        padding-left: 20px;
        border-right: 1px solid var(--Greyscale-200, #E3E8EF);
        border-left: 1px solid var(--Greyscale-200, #E3E8EF);
    }
}

.infos {
    padding: 30px 20px;
    border-radius: 12px;
    border: 1px solid var(--Greyscale-200, #E3E8EF);
    background: var(--Primary-white, #FFF);

}

@include respond("tab-port") {
    .inner {
        &.brr {
            padding-left: 0;
            border: 0;
        }
    }
}