@import "../theme/base/colors.scss";
@import "abstracts/functions";
.about{
    font-size:  rem(15);
    &-text *{
        font-size:  rem(15);

    }
    &-img{
        border-radius: 12px;
    }
    &-bottom-text{
        margin-top: rem(30);
        font-size: rem(15);
    }
}