@import "../theme/base/colors.scss";
@import "abstracts/functions";

section.partners {
    min-height: 400px;
    margin-bottom: 40px;

    .partner {
        color: $main;
        border: 1px solid #ccc;
        border-radius: 8px;
        display: block;
        cursor: pointer;

        &-detail {
            display: flex;
            align-items: center;
            height: 100%;
            flex-wrap: wrap;
            padding: 10px;
        }

        &-photo {
            padding: 15px 0;
            width: 100px;
            img {
                width: 100%;
                object-fit: cover;
                border-radius: 5px;
            }
        }

        &-position {
            overflow: hidden;
            margin-bottom: 20px;
        }

        &-more {
            font-weight: 500;
            span{
                font-size: rem(14);
            }
            margin-top: rem(15);

        }
    }

    h3 {
        color: $main;
        margin-bottom: 20px;
    }

   

}
.partner-detail{
    .partner{
        &-photo{
           border-radius: 12px;
           box-shadow: 0 0px 1px #00000042;
           height: 200px;
           img{
            height: 100%;
            object-fit: contain;
           }
           margin-bottom: rem(15);
        }
        &-name{
            margin-bottom: rem(15);
            font-size: 16px;
            font-weight: bold;
        }
    }
}
.cursor-pointer {
    cursor: pointer;
}

