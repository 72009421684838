@import "/src/assets/scss/abstracts/_functions.scss";
@import '../theme/base/colors.scss';

.ql-align-justify,
.tj {
    text-align: justify;
}

.br{
    border: 1px solid #ccc;
    border-radius: 5px;
}
body {
    overflow-x: hidden;
}

hr {
    height: 0.0625rem;
    margin: 1.25rem 0;
    border-color: $second;
}

*::-webkit-scrollbar {
    width: 8px;
    height: 5px;
}

*::-webkit-scrollbar-thumb {
    height: 3px;
    width: 5px;
    background-color: $main;
}

*::-webkit-scrollbar-thumb:hover {
    background-color: $second;
}

.mybtn {
    border-radius: 8px;
    height: 50px;
    font-size: rem(15);
    background-color: $white;
    border: 1px solid #ccc;
    transition: all .2s linear;
    &.active,&:hover {
        color: $second;
        background-color: $second;
        border: 1px solid $second;
        color: $white;
        font-weight: bold;
        svg *{
            stroke: $white;
        }
    }
    min-width: 50px;
    padding: 0.62rem 20px;
    a {
        color: #ccc;
    }
}

span.required {
    color: $red;
}

.nav-link {
    padding: .5rem;
}

.swiper {
    overflow-y: visible;
    &-pagination {
        bottom: -20px;
        &-bullet {
            width: 12px !important;
            height: 4px;
            border-radius: 4px;
            opacity: 1!important;
            transform: scale(1)!important;
            background: #CDD5DF!important;
            
            &-active-main, &-active {
                width: 28px !important;
                background: $second!important;
            }
        }
    }

    padding: 0;
    padding-top: 0;
}